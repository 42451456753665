import React from 'react';

const Gallery = () => {
    // Dynamically load images using a loop
    const images = Array.from({ length: 10 }, (_, index) =>
        require(`../assets/${index + 1}.gif`)
    );

    return (
        <div className='bg2 py-6 border-t-4 border-[gold]'>
            <p className='text-center py-10 text-[30px] text-[gold]'>HoW To PreSs</p>
            <div className="container  mx-auto px-4">
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                    {images.map((image, index) => (
                        <div key={index} className="overflow-hidden rounded-lg shadow-lg">
                            <img
                                src={image}
                                alt={`Gallery item ${index + 1}`}
                                className="w-full h-auto object-cover"
                            />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Gallery;
